import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from 'react-i18next';
import { Range } from 'react-range';
import PageBanner from "../layouts/PageBanner";

import bg from "../assets/images/banner/bnr4.jpg";
import UpdateBlog from "../components/Home/UpdateBlog";
import { getApi } from "../services/appUtilsAxios";
import { getImageUrl, showMessage } from "../services/helper";
import { addRequest } from "../services/validations";
import ErrorElement from "../components/ErrorElement";
import { postApi } from "../services/appUtilsAxios";
import { donatePrice, currency } from "../constant/theme";

const FundraiserDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { raise_id } = useParams();
  const [modalDonate, setModalDonate] = useState(false);
  const [referModal, setReferModal] = useState(false);
  const [totKeys, setTotKeys] = useState(0);
  const [project, setProject] = useState();
  const [selIds, setSelIds] = useState([]);
  const [donateVal, setDonateVal] = useState([0]);

  const {
    handleSubmit,
    reset,
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { donate_10:"0" },
    shouldFocusError: false,
    resolver: yupResolver(addRequest),
    mode: "onChange",
  });
  const getDetail = async () => {
    await getApi(`get-project-detail/${raise_id}?from=front`).then((resp) => {
      setProject({...resp?.data, sales: resp?.sales, sales_count: resp?.sales_count || 0, final_results_obj: resp?.final_results_obj});
      setTotKeys(resp?.data?.no_of_block - resp?.data?.purchased_block);
      //setValue('sel_block',`1_${resp?.data?.price_each_block}`);
    });
  };
  useEffect(() => {
    if (raise_id) {
      getDetail();
    }
  }, [raise_id]);

  const handleDonateMdl = () => {
    setValue('sel_block',selIds.join(", "));
    setModalDonate(true);
  };
  const onFormSubmit = async (values) => {
    const vars = values;
    vars.product_id = project._id;
    vars.is_show =  vars.is_show ? 1 : 0;
    /* const sel_block = values?.sel_block?.split("_");
    vars.price = (sel_block[0] * sel_block[1]);
    vars.no_of_block = sel_block[0]; */
    vars.no_of_block = selIds.length;
    vars.price = (selIds.length * project?.price_each_block);
    vars.donate_price = parseInt(vars.donate_10);// === "true" ? donatePrice : 0;
    vars.donate_10 = parseInt(values?.donate_10) > 0 ? "true" : "false";
    vars.total_price = parseInt(vars.price) + parseInt(vars.donate_price);
    vars.project_name = project?.name;
    
    //return false;
    localStorage.setItem('purchase_info', JSON.stringify(vars));
    return navigate("/payment");
    
    /* await postApi('add-raise-request', vars)
    .then((res)=>{
      if(res.status === 1){
        showMessage(1, "Fund raised successfull");
        setModalDonate(false);
        getDetail();
        setSelIds([]);
      }
    })
    .catch((error)=>{
      console.log('error', error)
      showMessage(0, error?.message);
    }); */
  };
  //console.log('vars', project);
  return (
    <>
      <div className="page-content bg-white">
        <PageBanner
          maintitle={ t('menu.home') }
          pagetitle={project?.name}
          background={bg}
        />
        <section className="content-inner-2">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 m-b30">
                <div className="fundraiser-single">
                  <div className="d-flex align-items-center justify-content-center project_detail">
                  <div id="container" style={{"--grid-rows": project?.no_of_rows, "--grid-cols": project?.no_of_cols, width:"100%"}}> {/* style={{backgroundImage: `url(${getImageUrl(project?.image)})`, backgroundRepeat:"none"}} */}
                      {project?.no_of_block && [...Array(project?.no_of_block).keys()].map((data, ind) => (
                        <div class={`grid-item ${project?.final_results_obj[`v_${ind+1}`] !== undefined ? `select` : ``}${selIds.indexOf((ind + 1).toString()) > -1 ? `clicked` : ``}`}
                        onClick={()=>{
                          if(project?.final_results_obj[`v_${ind+1}`] !== undefined){
                            return false;
                          } else {
                            const no = ind+1;
                            const noStr = no.toString();
                            if(selIds.indexOf(noStr) > -1){
                              const selIdsArr = [...selIds];
                              selIdsArr.splice(selIds.indexOf(noStr), 1);
                              setSelIds([...selIdsArr]);
                            } else {
                              setSelIds([...selIds, noStr]);
                            }
                          }
                        }}
                        >
                        {project?.final_results_obj[`v_${ind+1}`] !== undefined ? <img src={`${getImageUrl(`${project?._id}/img_${ind+1}.jpg`)}`} alt="" style={{height:"100%", width: "100%"}}/> : " "}
                        {project?.final_results_obj[`v_${ind+1}`] === undefined && <><span dangerouslySetInnerHTML={{__html: '&nbsp;'}}></span></>}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="fundraiser-gallery-wrapper" style={{marginTop:"10px"}}>
                    {/* <GallerySlider /> */}
                    
                    {/* <img
                      className="img-fluid"
                      src={getImageUrl(project?.image)}
                      alt={project?.name}
                    /> */}
                  </div>
                  <h2 className="title">{project?.name}</h2>
                  {/* <p>{project?.description}</p> */}

                  <h5>{ t('detail.about_the_fundraiser') }</h5>
                  <p>{project?.description}</p>
                  <ul className="fundraiser-bottom">  
                  
                    {project && project?.is_completed === 0 && selIds.length > 0 && <li>
                      <Link
                        to={"#"}
                        className="btn btn-primary btn-sm"
                        onClick={handleDonateMdl}
                        data-bs-target="#modalDonate"
                      >
                        <i className="flaticon-like me-2"></i> { t('button.donate_now') }
                      </Link>
                    </li>}
                  </ul>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4">
                <aside className="side-bar sticky-top">
                  <div className="donate_now">
                  {project && selIds.length > 0 &&
                    <p>
                      { t('detail.amount') } : {currency}{(selIds.length * project?.price_each_block)}
                    </p>}
                      <span className="p_bar"></span>
                      <small className="supporter">{project?.sales.length} {t('detail.supporters')}</small>
                      {project && project?.is_completed === 0 && selIds.length > 0 && <button type="button" class="btn btn-secondary btn-block" onClick={handleDonateMdl}
                        >{t('button.donate_now')}</button>}
                  </div>
                  <div className="doner_list">
                      <h2>{ t('detail.donor_list') }</h2>
                      {project?.sales.map((sale) => (
                      sale.is_show==0 ?
                      <div className="doner_detail" key={sale.id}>
                        <img src="/donor-icon.png"></img>
                        <div className="doner_user">
                          <p>{sale.name}</p>
                          <small>{currency}{sale.total_price} . <a href="#">{t('detail.recent-donation')}</a></small>
                        </div>
                      </div> : null
                     ))}
                  
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </section>
        
      </div>
      <Modal
        className="modal fade modal-wrapper"
        id="modalRefer"
        show={referModal}
        onHide={setReferModal}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title">Refer a Friend</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setReferModal(false)}
          >
            <i className="flaticon-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <form className="dz-form contact-bx" method="POST">
            <div className="form-group style-1 align-items-center">
              <label className="form-label">Name</label>
              <div className="input-group">
                <input
                  name="dzName"
                  required=""
                  type="text"
                  className="form-control"
                  placeholder="Jakob Bothman"
                />
              </div>
            </div>
            <div className="form-group style-1 align-items-center">
              <label className="form-label">Email address</label>
              <div className="input-group">
                <input
                  name="dzEmail"
                  required=""
                  type="text"
                  className="form-control"
                  placeholder="marseloque@mail.com"
                />
              </div>
            </div>
            <div className="form-group style-1 align-items-center">
              <label className="form-label">Phone Number</label>
              <div className="input-group">
                <input
                  name="dzPhoneNumber"
                  type="number"
                  className="form-control"
                  placeholder="Phone Number"
                />
              </div>
            </div>
            <div className="form-group mb-0 text-center">
              <button
                name="submit"
                type="submit"
                value="Submit"
                className="btn btn-primary"
              >
                Refer Now
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        className="modal fade modal-wrapper"
        id="modalDonate"
        show={modalDonate}
        onHide={setModalDonate}
      >
        <div className="modal-header">
          <h5 className="modal-title">{ t('detail.form.heading') }</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setModalDonate(false)}
          >
            <i className="flaticon-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div className="row">
              <div className="col-lg-12">
                <div className="tag-donate style-1">
                {/* {[...Array(totKeys).keys()].map((data, ind) => (
                  <div className="donate-categories">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id={`flexRadioDefault${ind}`}
                        value={`${(ind+1)}_${project?.price_each_block}`}
                        defaultChecked={ind === 0}
                        {...register('sel_block')}
                        // onChange={(e) => {setDonateVal((ind+1), project?.price_each_block)}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`flexRadioDefault${ind}`}
                      >
                        ${(ind+1) * project?.price_each_block}
                      </label>
                    </div>
                  </div>
                ))} */}
                <div className="input-group">
                  <div className="w-100">{/* form-check */}
                    <label
                      // className="form-check-label"
                      htmlFor={`Donate_10`}
                    >
                      { t('button.donate_10') } {currency}
                    </label>
                    {/* <input
                      // className="form-check-input"
                      type="range"
                      id={`Donate_10`}
                      min="0"
                      max="10"
                      {...register('donate_10')}
                    /> */}
                    <Range
                      step={1}
                      min={0}
                      max={10}
                      values={donateVal}
                      onChange={(val) => { setValue("donate_10", val[0]);  setDonateVal(val);}}
                      renderTrack={({ props, children }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: '6px',
                            width: '100%',
                            backgroundColor: '#ccc',
                            marginTop:"10px",
                            marginBottom:"10px"
                          }}
                        >
                          {children}
                        </div>
                      )}
                      renderThumb={({ props }) => (
                        <>
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: '30px',
                            width: '30px',
                            backgroundColor: 'var(--primary)',
                            textAlign: "center",
                            color:"#999",
                            borderRadius: "50%"
                          }}
                        >{props['aria-valuenow']}</div></>
                      )}
                    />
                    <input
                    name="donate_10"
                    type="hidden"
                    control={control}
                    {...register('donate_10')}
                  />
                  </div>
                </div>
                <div className="input-group">
                  <input
                    name="sel_block"
                    type="hidden"
                    control={control}
                    {...register('sel_block')}
                  />
                </div>
                <ErrorElement
                    name="sel_block"
                    error={!!errors.sel_block}
                    errorMessage={errors?.sel_block?.message}
                  />
                  {/* <p>
                    Most Donors donate approx <span>$1000</span> to this
                    Fundraiser
                  </p> */}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="form-label">{ t('detail.form.name') }</label>
                  <div className="input-group">
                    <input
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="Jakob Bothman"
                      control={control}
                      {...register('name')}
                    />
                  </div>
                  <ErrorElement
                    name="name"
                    error={!!errors.name}
                    errorMessage={errors?.name?.message}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="form-label">{ t('detail.form.email') }</label>
                  <div className="input-group">
                    <input
                      name="email"
                      type="text"
                      className="form-control"
                      placeholder="info@mail.com"
                      control={control}
                      {...register('email')}
                    />
                  </div>
                  <ErrorElement
                    name="email"
                    error={!!errors.email}
                    errorMessage={errors?.email?.message}
                  />
                </div>
              </div>
              <div className="col-lg-12">
              <div className="form-group">
              <div class="form-check">
  <input className="form-check-input" type="checkbox" name="is_show" control={control}
                      {...register('is_show')}/>
  <label className="form-check-label">{ t('detail.form.is_show') }</label>
</div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group mb-0 text-center">
                  <button
                    name="submit"
                    type="submit"
                    value="Submit"
                    className="btn btn-primary btn-block"
                  >
                    { t('button.donate_now') }
                  </button>
                </div>
                {/* Proceed To Pay */}
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default FundraiserDetail;
