import axios from 'axios';
import handleError from './handleError';

const apiBaseUrl = `${process.env.REACT_APP_API_URL}user/`;

function getToken() {
  return localStorage.getItem('token');
}

export const getHeaders = () => {
  return {
    headers: {
      Accept: 'application/json',
      'content-Type': 'application/json',
      /* ...(getToken() && {
        authorization: `Bearer ${getToken()}`
      }), */
    }
  };
};

export const postApi = (path, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiBaseUrl}${path}`, data, getHeaders())
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
};

export const getApi = (path) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiBaseUrl}${path}`, getHeaders())
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
};
